// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* handle to select a styleguide input */\n.vtex-styleguide-9-x-input {}\n\n/* removes edge clear button on input */\n.vtex-styleguide-9-x-hideDecorators::-ms-clear {\n  display: none;\n}\n\n.vtex-styleguide-9-x-noAppearance {\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n}\n\n/* placeholders are using c-muted-1's value */\n::-webkit-input-placeholder {\n  /* Chrome/Opera/Safari */\n  color: #979899;\n}\n::-moz-placeholder {\n  /* Firefox 19+ */\n  color: #979899;\n}\n:-ms-input-placeholder {\n  /* IE 10+ */\n  color: #979899;\n}\n:-moz-placeholder {\n  /* Firefox 18- */\n  color: #979899;\n}\n", "",{"version":3,"sources":["webpack://./react/components/Input/Input.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC,4BAAQ;;AAER,uCAAuC;AACvC;EACE,aAAa;AACf;;AAEA;EACE,wBAAgB;KAAhB,qBAAgB;UAAhB,gBAAgB;AAClB;;AAEA,6CAA6C;AAC7C;EACE,wBAAwB;EACxB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,WAAW;EACX,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":["/* handle to select a styleguide input */\n.input {}\n\n/* removes edge clear button on input */\n.hideDecorators::-ms-clear {\n  display: none;\n}\n\n.noAppearance {\n  appearance: none;\n}\n\n/* placeholders are using c-muted-1's value */\n::-webkit-input-placeholder {\n  /* Chrome/Opera/Safari */\n  color: #979899;\n}\n::-moz-placeholder {\n  /* Firefox 19+ */\n  color: #979899;\n}\n:-ms-input-placeholder {\n  /* IE 10+ */\n  color: #979899;\n}\n:-moz-placeholder {\n  /* Firefox 18- */\n  color: #979899;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "vtex-styleguide-9-x-input",
	"hideDecorators": "vtex-styleguide-9-x-hideDecorators",
	"noAppearance": "vtex-styleguide-9-x-noAppearance"
};
module.exports = ___CSS_LOADER_EXPORT___;
